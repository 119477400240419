/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */

import * as Sentry from "@sentry/browser";

// Error categories for better grouping
export const ERROR_CATEGORIES = {
  RECORDING: "recording_error",
  UPLOAD: "upload_error",
  DEVICE: "device_error",
  PERMISSION: "permission_error",
  NETWORK: "network_error",
  API: "api_error",
  AUTH: "auth_error",
  CHUNK: "chunk_error", // For chunk loading errors
  WORKER: "worker_error", // For web worker errors
  EXTERNAL_SCRIPT: "external_script_error", // For third-party script errors
  UNKNOWN: "unknown_error"
};

// Generic error patterns to group similar errors
const ERROR_PATTERNS = {
  CHUNK_LOAD: /loading.+chunk|chunk.+failed/i,
  WORKER_ERROR: /Worker|signInWorker|createError/i,
  NETWORK_GENERIC: /Network Error|network|CORS|timeout/i,
  UPLOAD_RELATED: /upload|blob|amazon|s3/i
};

// External script domains to ignore - focused on actual scripts used in the application
export const EXTERNAL_SCRIPT_DOMAINS = [
  // Video player related
  "vjs.zencdn.net",
  "unpkg.com/videojs",
  "unpkg.com/wavesurfer",
  "cdn.jsdelivr.net/npm/hls.js",
  "videojs-flash",
  "videojs-vjsdownload",
  "videojs.wavesurfer",
  "wavesurfer.js",

  // Analytics and marketing
  "js.stripe.com",
  "stripe.network",
  "r.wdfl.co",
  "hs-scripts.com",
  "hs-analytics.net",
  "hsleadflows.net",
  "hs-banner.com",
  "hsadspixel.net",
  "profitwell.com",
  "googletagmanager.com",
  "gtag/js",
  "gtm.js",
  "usemessages.com",
  "connect.facebook.net",
  "fbevents.js",

  // Authentication and security
  "accounts.google.com/gsi",
  "google.com/recaptcha",
  "gstatic.com/recaptcha",
  "recaptcha__en.js",
  "webworker.js",
  "enterprise.js",

  // Helpscout
  "helpscout.net"
];

// Blob URLs from the application that should be ignored
export const BLOB_URL_PATTERNS = [
  "blob:https://app.willotalent.com/"
];

// Consolidated list of all error patterns to ignore
export const IGNORED_ERROR_MESSAGES = [
  // Browser and permission errors
  "ResizeObserver loop limit exceeded",
  "Permission denied",
  "The object can not be found here.",
  "Could not start video source",
  "Permission dismissed",
  "The request is not allowed by the user agent or the platform in the current context",
  "candidate.videoCreate.mediaBlocked",
  "NotFoundError: Requested device not found",
  "NotAllowedError: play() failed because the user didn't interact with the document first",
  "Failed to execute 'querySelectorAll' on 'Element': 'map:not(svg map),canvas:not(svg canvas),del:not(svg del),ins:not(svg ins),a:not(svg a)' is not a valid selector",

  // User cancellation errors
  "Operation canceled by user",
  "Cancel",
  "canceled",
  "Request aborted",
  "Request cancelled",
  "The user aborted a request",

  // Video player errors
  "Error loading media",
  "MEDIA_ERR",
  "MEDIA_ELEMENT_ERROR",
  "Video not found",
  "Failed to load because no supported source was found",
  "The media could not be loaded",

  // Blob errors
  "Failed to load blob resource",
  "blob:"
];

// Rate-limited error patterns
export const RATE_LIMITED_ERRORS = [
  "ECONNABORTED",
  "Network Error",
  "Failed to fetch",
  "NetworkError",
  "The Internet connection appears to be offline",
  "timeout of ",
  "TimeoutError",
  "Request timeout",
  "Network request failed",
  "Firebase: Error (auth/network-request-failed)"
];

// Rate limiting configuration
export const RATE_LIMIT = {
  windowMs: 60000, // 1 minute
  maxEvents: 40, // max events per window
  networkMaxEvents: 5, // network errors per minute
  events: new Map()
};

/**
 * Check if an error is from an external script
 */
export const isExternalScriptError = error => {
  // Check error stack for external URLs
  if (error?.stack) {
    return EXTERNAL_SCRIPT_DOMAINS.some(domain => error.stack.includes(domain)) ||
           BLOB_URL_PATTERNS.some(pattern => error.stack.includes(pattern));
  }

  // Check error message for external URLs
  if (error?.message) {
    return EXTERNAL_SCRIPT_DOMAINS.some(domain => error.message.includes(domain)) ||
           BLOB_URL_PATTERNS.some(pattern => error.message.includes(pattern));
  }

  // Check error filename or source URL
  if (error?.filename || error?.sourceURL) {
    const source = error.filename || error.sourceURL;
    return EXTERNAL_SCRIPT_DOMAINS.some(domain => source && source.includes(domain)) ||
           BLOB_URL_PATTERNS.some(pattern => source && source.includes(pattern));
  }

  // Check for script load errors
  if (error?.target?.src && typeof error.target.src === "string") {
    return EXTERNAL_SCRIPT_DOMAINS.some(domain => error.target.src.includes(domain)) ||
           BLOB_URL_PATTERNS.some(pattern => error.target.src.includes(pattern));
  }

  // Check config URL if available (for axios errors)
  if (error?.config?.url) {
    return EXTERNAL_SCRIPT_DOMAINS.some(domain => error.config.url.includes(domain)) ||
           BLOB_URL_PATTERNS.some(pattern => error.config.url.includes(pattern));
  }

  return false;
};

/**
 * Check if an event contains an ignored error message
 */
export const hasIgnoredErrorMessage = error => {
  // Get all potential error message fields
  const errorTexts = [
    error?.message,
    error?.value,
    error?.response?.data?.message,
    error?.response?.data?.error,
    error?.code,
    error?.name
  ].filter(Boolean).map(msg => (typeof msg === "string" ? msg.toLowerCase() : ""));

  return IGNORED_ERROR_MESSAGES.some(pattern =>
    errorTexts.some(msg => msg.includes(pattern.toLowerCase())));
};

/**
 * Checks if the error should be rate limited
 */
export const shouldRateLimit = error => {
  const errorTexts = [
    error?.message,
    error?.value,
    error?.response?.data?.message,
    error?.code,
    error?.name
  ].filter(Boolean).map(msg => (typeof msg === "string" ? msg.toLowerCase() : ""));

  return RATE_LIMITED_ERRORS.some(pattern =>
    errorTexts.some(msg => msg.includes(pattern.toLowerCase())));
};

/**
 * Detect error category based on error details
 */
export const detectErrorCategory = error => {
  // Detect external script errors first
  if (isExternalScriptError(error)) {
    return ERROR_CATEGORIES.EXTERNAL_SCRIPT;
  }

  // Detect chunk loading errors
  if (ERROR_PATTERNS.CHUNK_LOAD.test(error?.message) ||
      error?.message?.includes("chunk")) {
    return ERROR_CATEGORIES.CHUNK;
  }

  // Detect worker errors
  if (ERROR_PATTERNS.WORKER_ERROR.test(error?.message)) {
    return ERROR_CATEGORIES.WORKER;
  }

  // Detect recording/media errors
  if (error?.name === "NotReadableError" ||
      error?.name === "NotAllowedError" ||
      error?.message?.includes("getUserMedia") ||
      error?.message?.includes("MediaRecorder")) {
    return ERROR_CATEGORIES.RECORDING;
  }

  // Detect upload errors
  if (error?.message?.includes("blob") ||
      error?.message?.includes("upload") ||
      error?.config?.url?.includes("upload-info") ||
      error?.config?.url?.includes("amazon") ||
      ERROR_PATTERNS.UPLOAD_RELATED.test(error?.message) ||
      ERROR_PATTERNS.UPLOAD_RELATED.test(error?.config?.url)) {
    return ERROR_CATEGORIES.UPLOAD;
  }

  // Detect device errors
  if (error?.name === "DevicesNotFoundError" ||
      error?.message?.includes("device") ||
      error?.message?.includes("camera") ||
      error?.message?.includes("microphone")) {
    return ERROR_CATEGORIES.DEVICE;
  }

  // Detect permission errors
  if (error?.name === "NotAllowedError" ||
      error?.message?.includes("permission")) {
    return ERROR_CATEGORIES.PERMISSION;
  }

  // Detect network errors
  if (error?.message?.includes("network") ||
      error?.message?.includes("Network Error") ||
      error?.request) {
    return ERROR_CATEGORIES.NETWORK;
  }

  // Detect auth errors
  if (error?.response?.status === 401 ||
      error?.response?.status === 403) {
    return ERROR_CATEGORIES.AUTH;
  }

  // Default to API error if we have a response
  if (error?.response) {
    return ERROR_CATEGORIES.API;
  }

  return ERROR_CATEGORIES.UNKNOWN;
};

/**
 * Get standardized endpoint pattern to group similar endpoints
 */
const getEndpointPattern = url => {
  if (!url) return "unknown";

  // Check for external domains first
  if (EXTERNAL_SCRIPT_DOMAINS.some(domain => url.includes(domain))) {
    return "external-script";
  }

  // Check for blob URLs
  if (BLOB_URL_PATTERNS.some(pattern => url.includes(pattern))) {
    return "blob-url";
  }

  // Extract meaningful parts of the URL
  const parts = url.split("/").filter(Boolean);

  // Group by resource type rather than specific endpoints
  if (url.includes("upload-info") || url.includes("amazon")) {
    return "upload-api";
  }

  // Group summary endpoints
  if (url.includes("summary")) {
    return "summary-api";
  }

  // Group by core resource types
  const resourceTypes = {
    candidates: "candidate-api",
    questions: "question-api",
    jobs: "job-api",
    interviews: "interview-api",
    users: "user-api",
    answers: "answer-api",
    recordings: "recording-api"
  };

  // Find matching resource type using array methods
  const matchedResource = Object.entries(resourceTypes)
    .find(([type]) => parts.includes(type));

  if (matchedResource) {
    return matchedResource[1]; // Return the pattern
  }

  // For worker/chunk errors, group by error type
  if (url.includes("chunk") || url.includes("worker")) {
    return "worker-chunk-error";
  }

  // Default to a generic API pattern
  return "generic-api";
};

/**
 * Get a standardized error title for grouping
 */
const getErrorTitle = (error, type, category) => {
  const endpoint = getEndpointPattern(error?.config?.url);

  switch (category) {
  case ERROR_CATEGORIES.EXTERNAL_SCRIPT:
    return `[${type}] External script error`;

  case ERROR_CATEGORIES.RECORDING:
    return `[${type}] Recording error: ${error?.name || "Unknown recording issue"}`;

  case ERROR_CATEGORIES.UPLOAD:
    return `[${type}] Upload error on ${endpoint}`;

  case ERROR_CATEGORIES.DEVICE:
    return `[${type}] Device error: ${error?.name || "Unknown device issue"}`;

  case ERROR_CATEGORIES.PERMISSION:
    return `[${type}] Permission error: ${error?.name || "Access denied"}`;

  case ERROR_CATEGORIES.NETWORK:
    return `[${type}] Network error on ${endpoint}`;

  case ERROR_CATEGORIES.AUTH:
    return `[${type}] Authentication error`;

  case ERROR_CATEGORIES.API:
    return `[${type}] ${error.response.status} error on ${endpoint}`;

  case ERROR_CATEGORIES.CHUNK:
    return `[${type}] Chunk loading error`;

  case ERROR_CATEGORIES.WORKER:
    return `[${type}] Worker error`;

  default:
    return `[${type}] Unknown error`;
  }
};

/**
 * Get error grouping fingerprint based on category
 */
const getErrorFingerprint = (error, type, category) => {
  const endpoint = getEndpointPattern(error?.config?.url);
  const baseFingerprint = [type, category];

  switch (category) {
  case ERROR_CATEGORIES.EXTERNAL_SCRIPT:
    return [
      ...baseFingerprint,
      "external_script_error"
    ];

  case ERROR_CATEGORIES.RECORDING:
    return [
      ...baseFingerprint,
      error?.name || "unknown_recording_error",
      error?.message?.includes("getUserMedia") ? "getUserMedia" : "recording"
    ];

  case ERROR_CATEGORIES.UPLOAD:
    return [
      ...baseFingerprint,
      endpoint,
      error?.response?.status ? String(error.response.status) : "unknown_status"
    ];

  case ERROR_CATEGORIES.DEVICE:
    return [
      ...baseFingerprint,
      error?.name || "unknown_device_error",
      error?.message?.includes("camera") ? "camera" :
      error?.message?.includes("microphone") ? "microphone" : "device"
    ];

  case ERROR_CATEGORIES.PERMISSION:
    return [
      ...baseFingerprint,
      error?.name || "unknown_permission_error"
    ];

  case ERROR_CATEGORIES.NETWORK:
    return [
      ...baseFingerprint,
      endpoint
    ];

  case ERROR_CATEGORIES.AUTH:
    return [
      ...baseFingerprint,
      String(error?.response?.status || "unknown")
    ];

  case ERROR_CATEGORIES.API:
    return [
      ...baseFingerprint,
      endpoint,
      String(error?.response?.status || "unknown"),
      error?.response?.data?.error || "unknown_error"
    ];

  default:
    return [
      ...baseFingerprint,
      "unknown_error"
    ];
  }
};

/**
 * Apply rate limiting to an event
 */
export const isRateLimited = (eventKey, error) => {
  const now = Date.now();
  const isNetworkError = shouldRateLimit(error);
  const maxEvents = isNetworkError ? RATE_LIMIT.networkMaxEvents : RATE_LIMIT.maxEvents;
  const eventTimes = RATE_LIMIT.events.get(eventKey) || [];

  // Clean up old events
  const recentEvents = eventTimes.filter(time => time > now - RATE_LIMIT.windowMs);

  if (recentEvents.length >= maxEvents) {
    return true; // Rate limited
  }

  // Update events
  RATE_LIMIT.events.set(eventKey, [...recentEvents, now]);
  return false; // Not rate limited
};

/**
 * Generate event key for rate limiting
 */
const getEventKey = (error, type, category) => `${type}_${category}_${error?.response?.status || "unknown"}`;

/**
 * Enhanced error logging utility with rate limiting and external script filtering
 */
export const logErrors = ({ error, payload = null, context = {} }) => {
  const {
    userId = null,
    questionId = null,
    jobId = null,
    email = null,
    answerId = null,
    info = null,
    type = "candidate"
  } = context;

  // Convert event to error-like object if needed
  const errorObj = error?.exception?.values?.[0]
    ? {
      name: error.exception.values[0].type,
      message: error.exception.values[0].value,
      stack: error.exception.values[0].stacktrace?.frames?.map(f => f.filename).join("\n"),
      response: error.exception.values[0].mechanism
        ? { status: error.exception.values[0].mechanism.data?.status }
        : undefined
    }
    : error;

  // Detect error category
  const category = detectErrorCategory(errorObj);

  // Skip external script errors entirely
  if (category === ERROR_CATEGORIES.EXTERNAL_SCRIPT) {
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
      console.log(`[${category}] External script error ignored:`, errorObj);
    }
    return;
  }

  // Early exit for ignored errors
  if (hasIgnoredErrorMessage(errorObj)) {
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
      console.log("Ignored error:", errorObj?.message || errorObj);
    }
    return;
  }

  // Generate event key for rate limiting
  const eventKey = getEventKey(errorObj, type, category);

  // Check rate limiting
  if (isRateLimited(eventKey, errorObj)) {
    if (process.env.NODE_ENV === "development" || process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
      console.log(`Rate limited ${shouldRateLimit(errorObj) ? "network " : ""}error event: ${eventKey}`);
    }
    return;
  }

  // Clean and prepare context
  const extraContext = {
    timestamp: new Date().toISOString(),
    userAgent: navigator.userAgent,
    errorCategory: category,
    payload: payload ? JSON.stringify(payload) : null,
    ...Object.entries(context)
      .reduce((acc, [key, value]) => (value != null ? { ...acc, [key]: value } : acc), {})
  };

  // Create error event with better title for grouping
  const errorEvent = new Error(getErrorTitle(errorObj, type, category));
  errorEvent.stack = errorObj?.stack;

  // Skip certain errors in production
  const ignoredErrors = process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true"
    ? [403, 401]
    : [404, 403, 401];

  if (ignoredErrors.includes(errorObj?.response?.status)) {
    return;
  }

  try {
    // Set user context for candidates
    if (type === "candidate" && (userId || email)) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: userId, email });
      });
    }

    Sentry.withScope(scope => {
      // Set error category and type tags
      scope.setTag("error_category", category);
      scope.setTag("error_type", type);

      // Set common tags
      if (userId) scope.setTag("userId", userId);
      if (questionId) scope.setTag("questionId", questionId);
      if (jobId) scope.setTag("jobId", jobId);
      if (errorObj?.response?.status) scope.setTag("status_code", errorObj.response.status);

      // Add request context if available
      if (errorObj.response || errorObj.request) {
        scope.setContext("request", {
          url: errorObj.config?.url,
          method: errorObj.config?.method,
          status: errorObj.response?.status,
          endpoint: getEndpointPattern(errorObj.config?.url)
        });
      }

      // Add business context
      if (userId || questionId || jobId || email || answerId || info) {
        scope.setContext(type, {
          userId,
          questionId,
          jobId,
          answerId,
          email,
          info
        });
      }

      // Add error details context
      scope.setContext("error_details", {
        name: errorObj?.name,
        message: errorObj?.message,
        category,
        status: errorObj?.response?.status,
        ...{ ...errorObj.response, data: errorObj?.response?.data }
      });

      // Add extra debugging context
      scope.setContext("extra", extraContext);

      // Set fingerprint for error grouping
      scope.setFingerprint(getErrorFingerprint(errorObj, type, category));

      if (process.env.NODE_ENV === "development" || process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
        console.log("Sending error to Sentry:", errorEvent);
      }

      // Use captureException for error objects and captureEvent for custom events
      if (errorObj instanceof Error || errorObj?.response) {
        Sentry.captureException(errorEvent);
      } else {
        // For custom events, create a structured event
        Sentry.captureEvent({
          message: errorEvent.message,
          level: "error",
          extra: extraContext,
          tags: {
            error_category: category,
            error_type: type,
            status_code: errorObj?.response?.status
          }
        });
      }
    });
  } catch (e) {
    // Fallback console logging if Sentry fails
    console.error("Failed to send to Sentry:", e);
    console.error("Original error:", errorObj);
    console.error("Context:", extraContext);
  }

  // Console logging in development
  if (process.env.NODE_ENV === "development" || process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
    console.log(`[${category}] Error:`, {
      error: errorObj,
      category,
      context: extraContext,
      endpoint: getEndpointPattern(errorObj.config?.url)
    });
  }
};

/**
 * Convert Sentry event to error-like object for category detection
 */
export const eventToError = event => {
  const exception = event.exception?.values?.[0];
  return {
    name: exception?.type,
    message: exception?.value,
    stack: exception?.stacktrace?.frames?.map(f => f.filename).join("\n"),
    response: exception?.mechanism ? { status: exception.mechanism.data?.status } : undefined
  };
};

/**
 * Function to add additional domain to the list of ignored domains
 */
export const addExternalScriptDomain = domain => {
  if (typeof domain === "string" && !EXTERNAL_SCRIPT_DOMAINS.includes(domain)) {
    EXTERNAL_SCRIPT_DOMAINS.push(domain);
    return true;
  }
  return false;
};

/**
 * Get Sentry beforeSend function that uses our unified error handling
 */
export const getSentryBeforeSend = () => event => {
  // Extract error from Sentry event format
  const errorObj = eventToError(event);

  // Check for ignored error messages
  if (hasIgnoredErrorMessage(errorObj)) {
    return null;
  }

  // Check for external script errors
  if (isExternalScriptError(errorObj)) {
    return null;
  }

  // Use shared error category detection from helperLogging
  const category = detectErrorCategory(errorObj);

  // Get event key for rate limiting
  const eventKey = `${category}_${event.level || "error"}`;

  // Apply rate limiting
  if (isRateLimited(eventKey, errorObj)) {
    return null;
  }

  // Add category tag for better filtering
  if (!event.tags) event.tags = {};
  event.tags.error_category = category;

  // Log events to console in development
  if (process.env.REACT_APP_SENTRY_DEBUG_ENABLED === "true") {
    console.group("Sentry Event");
    console.log("Event:", event);
    console.log("Tags:", event.tags);
    console.log("Extra:", event.extra);
    console.log("User:", event.user);
    console.log("Contexts:", event.contexts);
    console.log("Category:", category);
    console.groupEnd();
  }

  return event;
};
