/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";
import Lottie from "react-lottie";
import { Container, Row, Col } from "react-bootstrap";
import { animations } from "react-animation";

import animationData from "assets/images/user_experience.json";
import Header from "containers/Common/HeaderContainer";
import Breadcrumbs from "components/Breadcrumbs";
import Message from "components/Common/Message";
import TooltipSuccess from "components/Common/Tooltip/Success";
import PersonalizedInvites from "components/Forms/PersonalizedInvites";
import InviteAllCandidates from "components/Forms/InviteAllCandidates";
import MoreInfo from "components/Forms/InviteAllCandidates/MoreInfo";
import InviteCandidates from "components/Tables/InviteCandidates";
import Spinner from "components/Common/Spinner";
import StatusJob from "components/Status/Job";
import Notification from "components/Notifications/Notification";
import PageWrapper from "hoc/PageWrapper";
import { jobsInviteBreadcrumbs } from "mixins/dynamicRoutes";
import { copyTextFromButton } from "mixins/helpers";
import { Location } from "mixins/svgIcons";
import { checkValidity, checkValidityWithoutPhone } from "mixins/helperJobInvites";
import { JOB_STATUSES } from "configs/jobs/constants";
import PersonaButtonWrapper from "components/PersonaVerification/PersonaButtonWrapper.tsx";
import "./styles.scss";
import { getCurrentUser } from "store/modules/users/selectors";
import { store } from "store";
import { fetchNotifications } from "store/modules/notifications/actions";
import useVerificationStore, { VerificationStatus } from "store/verificationStore";
import { connect } from "react-redux";
import { getProfileInfo } from "store/modules/profile/selectors";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Invite = ({
  t,
  error,
  match: {
    params: { id = null }
  },
  history,
  isLoading,
  isSendingUploading,
  isJobInvitesSend,
  jobCurrent = {},
  jobInvitesData,
  jobInvitesParseData,
  addJobInvite,
  sendInvites,
  fetchCurrentJob,
  sendSpreadsheet,
  updateJobInvite,
  setModal,
  clearJobInvites,
  clearErrors,
  clearJobInvitesParseData,
  setJobInvites,
  parseError,
  profileInfo
}) => {
  const {
    bulk_invites_enabled: bulkInvitesEnabled,
    key: agencyKey
  } = getCurrentUser(store.getState());
  const [showTooltip, setShowTooltip] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const linkRef = useRef(null);
  const buttonRef = useRef(null);
  const tableRef = useRef(null);

  const errorCounter = useMemo(() => (jobInvitesData?.length
    ? jobInvitesData.reduce(
      (previous, current) => (checkValidity(current) ? previous : previous + 1), 0
    ) : 0), [jobInvitesData]);
  const errorCounterWithoutPhone = useMemo(() => (jobInvitesData?.length
    ? jobInvitesData.reduce(
      (previous, current) => (checkValidityWithoutPhone(current) ? previous : previous + 1), 0
    ) : 0), [jobInvitesData]);

  const { verificationStatus, initiateVerification } = useVerificationStore();

  useEffect(() => {
    if ([VerificationStatus.UNVERIFIED,
      VerificationStatus.IN_PROGRESS].includes(verificationStatus)) {
      initiateVerification();
    } else if (verificationStatus === VerificationStatus.PROCESSING) {
      if (window.Beacon) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", {
          subject: "ID Verification Status – Request for Update",
          text: `Hi Willo Support Team,

I've submitted all the required documents for my ID and agency verification, and I've noticed my status is currently marked as "processing."

Could you please provide an update on the status of my verification or let me know if any additional information is needed from my side?

Thanks,

${profileInfo.full_name}
${profileInfo.agency_name}`
        });
      }
    }
  }, [verificationStatus]);


  useEffect(() => {
    clearErrors();
    clearJobInvites();
    fetchCurrentJob(id);

    return () => clearErrors();
  }, []);

  const scrollToInvite = () => {
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  const handleJobInvite = props => {
    addJobInvite(props);
    scrollToInvite();
  };

  const handleSendSpreadsheet = props => {
    sendSpreadsheet(props);
    scrollToInvite();
  };

  useEffect(
    () => () => {
      // We need this if user push back btn into the browser
      const { pathname } = history.location;
      const isBackAction = history.action === "POP";
      const isPreviousJobPreviewPage =
        pathname.includes("jobs/edit/preview") ||
        pathname.includes("jobs/create/preview");

      if (isBackAction && isPreviousJobPreviewPage) {
        const setupUrl = pathname.replace("preview", "setup");

        history.push(setupUrl);
      }
    },
    [history]
  );

  useEffect(() => {
    if (jobInvitesData?.length === 0) {
      clearErrors();
    }
  }, [jobInvitesData]);

  const tooltipTimout = useRef({});

  const clearTooltipTimer = () => {
    if (tooltipTimout?.current?.hideTooltip) {
      clearTimeout(tooltipTimout.current.hideTooltip);
    }
    if (tooltipTimout?.current?.changeTooltipTitle) {
      clearTimeout(tooltipTimout.current.changeTooltipTitle);
    }
  };

  const animateTooltip = () => {
    clearTooltipTimer();

    setShowTooltip(true);
    setIsCopied(true);

    tooltipTimout.current.hideTooltip = setTimeout(() => {
      setShowTooltip(false);
    }, 2750);
    tooltipTimout.current.changeTooltipTitle = setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const handleCopyText = () => {
    copyTextFromButton(linkRef);
    animateTooltip();
  };

  const handleShowTooltip = useCallback(() => {
    setIsCopied(false);
    clearTooltipTimer();
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    clearTooltipTimer();
    setShowTooltip(false);
  }, []);

  useEffect(() => {
    if (jobCurrent.invite_link && linkRef.current && id === jobCurrent?.key) {
      handleShowTooltip();
      handleCopyText();
    } else {
      handleHideTooltip();
    }
  }, [jobCurrent.invite_link, linkRef.current, isLoading]);

  const handleInvite = () => {
    sendInvites(id);
    store.dispatch(fetchNotifications({ key: agencyKey }));
  };

  return (
    <>
      {(!isLoading && parseError) ? (
        <Notification
          type="alert"
          message={(
            <div className="invite__page-error-message">
              {
                parseError === "sendSpreadsheetError" ? (
                  <>
                    <p>{t("job.invite.table.importFailed.0")}</p>
                    <MoreInfo popoverText={t("job.invite.table.importFailed.1")} />
                  </>
                ) : (
                  <p>{t("job.invite.table.importEmpty")}</p>
                )
              }
            </div>
          )}
          onClick={null}
          animation={animations.popIn}
          className="invite__page"
        />
      ) : null}
      <PageWrapper header={<Header />}>
        <Container>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Row justify="center">
                <Col md={12}>
                  <Breadcrumbs
                    pathList={jobsInviteBreadcrumbs(t, jobCurrent.title, id)}
                  />
                </Col>
              </Row>

              <Row justify="center">
                <Col md={8}>
                  <header className="header-job__header">
                    <h1 className="header-job__header-title n-font-giant">
                      <span className="header-job__header-title-text">{jobCurrent?.title || ""}</span>
                      <StatusJob isLive={jobCurrent.status === JOB_STATUSES.LIVE} />
                    </h1>

                    <p className="header-job__additional-info n-font-medium flex flex-row items-center">
                      <span className="header-job__additional-info-company">
                        {jobCurrent?.company?.name || ""}
                      </span>
                      {jobCurrent?.company?.location ? (
                        <span className="header-job__additional-info-location flex flex-row items-center company-location">
                          <Location svgIconClass="header-job__additional-info-icon" />
                          {jobCurrent?.company?.location}
                        </span>
                      ) : null}
                    </p>
                  </header>
                </Col>
              </Row>

              <Lottie options={defaultOptions} height={150} width={200} />

              <Row justify="center" bsPrefix="row invite-info__candidates-row">
                <Col md={12}>
                  <h2 className="invite-all__title">
                    {t("job.invite.inviteOne.title")}
                  </h2>
                  <p className="invite-all__text">
                    {t("job.invite.inviteOne.text")}
                    <br />
                    {t("job.invite.inviteOne.recomended")}
                  </p>
                </Col>

                <Col md={{ span: 6, offset: 3 }}>
                  <div className="invite-by-link">
                    <div className="invite-by-link__wrapper">
                      <a
                        className="invite-by-link__link n-font-small n-black-100"
                        href={jobCurrent.invite_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="share link"
                        ref={linkRef}
                      >
                        {jobCurrent.invite_link}
                      </a>

                      <PersonaButtonWrapper>
                        <button
                          className="
                          n-button__small
                          n-bg-purple-100
                          n-white
                          copy-link
                        "
                          onClick={handleCopyText}
                          onMouseEnter={handleShowTooltip}
                          onMouseLeave={handleHideTooltip}
                          ref={buttonRef}
                          type="button"
                        >
                          {t("button.copyLink")}
                        </button>
                      </PersonaButtonWrapper>

                      <TooltipSuccess
                        position="top"
                        message={isCopied ? t("job.invite.inviteOne.buttonCopiedToClipboard") : t("job.invite.inviteOne.buttonTip")}
                        target={buttonRef.current}
                        show={showTooltip}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row justify="center">
                {
                  bulkInvitesEnabled && (
                    <>
                      <Col md={12}>
                        <InviteAllCandidates jobKey={id} sendSpreadsheet={handleSendSpreadsheet} />
                      </Col>
                      <Col md={12}>
                        <PersonalizedInvites addJobInvite={handleJobInvite} />
                      </Col>
                    </>
                  )
                }

                <Col md={12}>
                  {isJobInvitesSend && (
                    <Message message={t("job.invite.table.success")} />
                  )}

                  {jobInvitesData.length > 0 &&
                    error &&
                    Object.keys(error).map(errorNumber => (
                      <div key={uuidv4()} className="invite-info__error">
                        <span className="invite-info_error-title">
                          {`${Number(errorNumber) + 1}:`}
                        </span>
                        <ul className="invite-info__error-list">
                          {error[errorNumber].non_field_errors && (
                            <li className="invite-info__error-list-item">
                              {error[errorNumber].non_field_errors}
                            </li>
                          )}
                          {error[errorNumber].email && (
                            <li className="invite-info__error-list-item">
                              {error[errorNumber].email}
                            </li>
                          )}
                          {error[errorNumber].phone && (
                            <li className="invite-info__error-list-item">
                              {error[errorNumber].phone}
                            </li>
                          )}
                        </ul>
                      </div>
                    ))}
                  <InviteCandidates
                    jobInvitesData={jobInvitesData}
                    jobInvitesParseData={jobInvitesParseData}
                    updateJobInvite={updateJobInvite}
                    setModal={setModal}
                    errorCounter={errorCounter}
                    setJobInvites={setJobInvites}
                    clearJobInvitesParseData={clearJobInvitesParseData}
                  />
                </Col>

                <Col md={12} ref={tableRef}>
                  {jobInvitesData.length > 0 && (
                    <div className="invite-info__buttons">
                      <div className="button__group_center">
                        {isSendingUploading ? (
                          <div className="button__wrapper button__medium-large">
                            <Spinner />
                          </div>
                        ) : (
                          <>
                            <button
                              className="n-button__large-border n-border-purple-100 n-purple-100"
                              type="button"
                              onClick={() => clearJobInvites()}
                            >
                              {t("button.cancel")}
                            </button>

                            <button
                              className="n-button__large n-bg-purple-100 n-white"
                              type="button"
                              onClick={handleInvite}
                              disabled={!!errorCounterWithoutPhone}
                            >
                              {t("button.invite")}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </PageWrapper>
    </>
  );
};

Invite.defaultProps = {
  error: false,
  isLoading: false,
  isSendingUploading: false,
  isJobInvitesSend: false,
  jobCurrent: {},
  jobInvitesData: undefined,
  jobInvitesParseData: null
};

Invite.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isSendingUploading: PropTypes.bool,
  isJobInvitesSend: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    action: PropTypes.string
  }).isRequired,
  jobCurrent: PropTypes.shape({
    title: PropTypes.string,
    candidates_cnt: PropTypes.number,
    applications_cnt: PropTypes.number,
    company: PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string
    }),
    created_at: PropTypes.string,
    deadline: PropTypes.string
  }),
  jobInvitesData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string
    })
  ),
  jobInvitesParseData: PropTypes.shape({
    total: PropTypes.number,
    success: PropTypes.number
  }),
  addJobInvite: PropTypes.func.isRequired,
  sendInvites: PropTypes.func.isRequired,
  sendSpreadsheet: PropTypes.func.isRequired,
  fetchCurrentJob: PropTypes.func.isRequired,
  updateJobInvite: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  clearJobInvites: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  clearJobInvitesParseData: PropTypes.func.isRequired,
  setJobInvites: PropTypes.func.isRequired,
  parseError: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  profileInfo: getProfileInfo(state)
});

export default connect(mapStateToProps)(withTranslation()(Invite));
