/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { isIE } from "react-device-detect";

import PrivateRoute from "containers/PrivateRoute";
import PrivateRouteAuth from "containers/PrivateRouteAuth";
import PrivateRouteCandidate from "containers/PrivateRouteCandidate";

import TermsAndConditions from "views/Documents/TermsAndConditions";
import PrivacyPolicy from "views/Documents/PrivacyPolicy";
import TestPage from "views/TestPage";
import CommonNotFound from "views/Common/NotFound";
import CommonNotAccess from "views/Common/NotAccess";
import ChangeBrowserExployer from "views/Common/ChangeBrowser/Exployer";

import ResetSendEmail from "containers/Auth/ResetSendEmailContainer";
import ResetPassword from "containers/Auth/ResetPasswordContainer";
import MultiFA from "containers/Auth/MultiFAContainer";
import MultiFADisable from "containers/Auth/MultiFADisableContainer";
import Jobs from "containers/Jobs";
import Profile from "containers/Profile/ProfileContainer";
import Users from "containers/Users/UsersContainer";
import PrivateWrapper from "containers/Hoc/PrivateWrapperContainer";
import Companies from "containers/Companies";
import People from "containers/People";
import Onboarding from "containers/Onboarding";
import Security from "containers/Security";
import Libraries from "containers/Libraries";
import CreateTemplate from "containers/Libraries/CreateTemplate";
import EditTemplate from "containers/Libraries/EditTemplate";
import CompaniesOverview from "containers/Companies/Overview";
import CompanyCreate from "containers/Companies/Create";
import CompanyEdit from "containers/Companies/Edit";
import CandidatePublic from "containers/Candidate/Public";
import BulkCandidatePublic from "containers/Candidate/Bulk";
import VideoQuestionsTest from "containers/Candidate/VideoQuestionsTest";
import Dashboard from "containers/Dashboard";
import JobInvite from "containers/Jobs/Invite";
import JobOverview from "containers/Jobs/Overview";
import JobCandidate from "containers/Jobs/Candidate";
import JobInfo from "containers/Jobs/JobInfo";
import CreateJob from "containers/Jobs/CreateScreen";
import SavedForLater from "containers/Candidate/SavedForLater";
import Plans from "containers/Billing/Plans";
import TrialCompleted from "containers/Billing/TrialCompleted";
import SubscriptionFailed from "containers/Billing/SubscriptionFailed";
import StripeSession from "containers/Billing/StripeSession";
import IdvCheckoutSession from "containers/Connect/IdvCheckoutSession/IdvCheckoutSession";
import IDVSession from "containers/Candidate/IDVSession";
import ShareCode from "containers/Candidate/ShareCode";
import IdvCompleted from "views/Candidate/IDV/IdvCompleted";
import Maintenance from "containers/Maintenance";
import ConnectionLost from "containers/ConnectionLost";

import { BeatLoader } from "react-spinners";
import { store } from "store";
import { lazyRetry } from "mixins/helpers";
import { saveIsOnline } from "store/modules/widgets/actions";
import {
  getIsStorageSupported,
  isCandidateRoute,
  isCurrentUserCandidate
} from "mixins/helperCandidate";
import ErrorFallback from "components/Common/Error/ErrorFallback/ErrorFallback";
import useVerificationStore from "store/verificationStore.ts";
import { StripeVerificationStatus } from "constants/stripe.ts";
import { isTestPOEditorEnabled } from "configs/jobs/constants";

const Marketplace = React.lazy(() =>
  lazyRetry(() => import("../containers/Marketplace")));
const ConnectToIntegration = React.lazy(() =>
  lazyRetry(() => import("../views/Connect/ConnectToIntegration")));
const SignIn = React.lazy(() =>
  lazyRetry(() => import("../containers/Auth/SignInContainer")));
const SignUp = React.lazy(() =>
  lazyRetry(() => import("../containers/Auth/SignUpContainer")));
const CandidateJobInfo = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/JobInfo")));
const CommonJob = React.lazy(() =>
  lazyRetry(() => import("../containers/CommonJob")));
const InterviewSubmitted = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/InterviewSubmitted")));
const InterviewClosed = React.lazy(() =>
  lazyRetry(() => import("../views/Common/InterviewClosed")));
const SampleError = React.lazy(() =>
  lazyRetry(() => import("../containers/SampleError/SampleError")));
const ScoreCardLibrary = React.lazy(() =>
  lazyRetry(() =>
    import("../views/ScoreCard/ScoreCardLibrary/ScoreCardLibrary")));
const ScoreCardCreate = React.lazy(() =>
  lazyRetry(() => import("../views/ScoreCard/ScoreCardCreate")));
const VideoQuestionsCreate = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/VideoQuestionsCreate")));
const CandidateTips = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/Tips")));
const CandidateVideoPreview = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/VideoPreview")));
const CandidateCalendar = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/Calendar")));
const CandidateComplete = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/Complete")));
const CanidateTransferred = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/Transferred")));
const CanidateTransferredLoading = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/TransferredLoading")));
const CanidateTransferredCancelled = React.lazy(() =>
  lazyRetry(() => import("../containers/Candidate/TransferredCancelled")));
const ShowcaseLink = React.lazy(() =>
  lazyRetry(() => import("../views/Common/ShortLink/ShowcaseLink.tsx")));
const ShortLink = React.lazy(() =>
  lazyRetry(() => import("../views/Common/ShortLink/ShortLink.tsx")));
const PageNotFound = React.lazy(() =>
  lazyRetry(() => import("../views/Common/PageNotFound")));

const AccountVerification = React.lazy(() =>
  lazyRetry(() => import("../views/Auth/AccountVerification/AccountVerification.tsx")));
const EmailVerificationPage = React.lazy(() =>
  lazyRetry(() => import("../views/Auth/EmailVerification/EmailVerification.tsx")));
const StripeVerificationFailed = React.lazy(() =>
  lazyRetry(() => import("../views/Auth/StripeVerificationFailed/StripeVerificationFailed.tsx")));

const POEditor = React.lazy(() =>
  lazyRetry(() => import("../views/Test/POEditor")));

const Router = ({
  history: { location },
  hasAdminRight,
  isTrialCompleted,
  isSubscriptionFailed,
  isSetupCompleted,
  isTrialOrPaid,
  refreshStatus,
  isOnline,
  isAccountLocked,
  isAuthenticated,
  isEmailVerified
}) => {
  const handleSetOnline = status => {
    if (
      process.env.REACT_APP_CONNECTION_LOST_ENABLED === "true" &&
      !isCurrentUserCandidate
    ) {
      store.dispatch(saveIsOnline(status));
    }
  };

  const { verificationStatus } = useVerificationStore();

  const isStripeVerificationFailed = verificationStatus === StripeVerificationStatus.FAILED;


  useEffect(() => {
    if (getIsStorageSupported()) {
      window.sessionStorage.setItem(
        "isCandidate",
        isCandidateRoute ? "true" : "false"
      );
    }
  }, [isCandidateRoute, location?.pathname, getIsStorageSupported]);

  useEffect(() => {
    function onlineHandler() {
      handleSetOnline(true);
    }

    function offlineHandler() {
      handleSetOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  useEffect(() => {
    const windowHistory = window.history;

    window.scrollTo(0, 0);
    if ("scrollRestoration" in windowHistory) {
      windowHistory.scrollRestoration = "manual";
    }
    refreshStatus();
  }, [location]);


  useEffect(() => {
    if (getIsStorageSupported()) {
      const failedPage = window.sessionStorage.getItem("failedPage") ?? "";

      if (location?.pathname !== failedPage) {
        window.sessionStorage.setItem("retry", 0);
      }
    }
  }, [location?.pathname]);

  if (
    !isOnline &&
    process.env.REACT_APP_CONNECTION_LOST_ENABLED === "true" &&
    !isCurrentUserCandidate && !isCandidateRoute
  ) {
    return <Route path="*" exact component={ConnectionLost} />;
  }

  if (process.env.REACT_APP_REDIRECT_ALL_TO_MAINTENANCE === "true") {
    return <Route path="*" exact component={Maintenance} />;
  }

  if (isIE) {
    return <Route path="*" exact component={ChangeBrowserExployer} />;
  }

  return (
    <React.Suspense
      fallback={
        <div className="loader-page">
          <BeatLoader color="#5a2af1" loading />
        </div>
      }
    >
      <Switch>
        <Route path="/" exact>
          <Redirect to={isAuthenticated ? "/jobs" : "/sign-in"} />
        </Route>
        <Route path="/show/:id/" exact component={ShowcaseLink} />
        <Route path="/error" exact component={ErrorFallback} />
        <Route path="/invite/:id/" exact component={ShortLink} />
        <Route path={["/invite/:id//", "/invite/:id/:other/"]}>
          <Redirect to="/invalid-link" />
        </Route>
        <Route path="/group/:id/" exact component={ShortLink} />
        <Route path="/gi/:id/" exact component={ShortLink} />
        <Route path="/common/:jobId" exact component={CommonJob} />
        <Route path="/public-candidate/:tokenId/" exact component={CandidatePublic} />
        <Route path="/public-candidates/:tokenId/not-found" exact component={PageNotFound} />
        <Route
          path="/bulk-public-candidates/:tokenId/"
          component={BulkCandidatePublic}
        />
        {/* @TODO: Initially used if showcase links are expired but now moved to /page-not-found */}
        <Route
          path="/common-not-found/:jobCreatorEmail"
          component={CommonNotFound}
        />
        <Route path="/interview-closed" component={InterviewClosed} />
        <Route path="/invalid-link" component={PageNotFound} />
        <Route path="/page-not-found" component={PageNotFound} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/maintenance" component={Maintenance} />
        <Route path="/connection-lost">
          {!isOnline && !isCandidateRoute && !isCurrentUserCandidate ? (
            <ConnectionLost />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        <PrivateRouteAuth path="/sign-up" exact component={SignUp} />
        <PrivateRouteAuth path="/sign-in" exact component={SignIn} />
        <PrivateRouteAuth
          path="/activate/:uid/:token"
          exact
          component={SignUp}
        />
        <PrivateRouteAuth path="/appsumo/:code" exact component={SignUp} />
        <PrivateRouteAuth path="/linked" exact component={LinkedInPopUp} />
        <Route
          path="/reset-send-email"
          exact

          component={ResetSendEmail}
        />
        <Route
          path="/reset-password/:code/:id"
          component={ResetPassword}
          exact
        />
        <PrivateRouteAuth path="/multifa" exact component={MultiFA} />
        <PrivateRouteAuth
          path="/multifa-disable"
          exact
          component={MultiFADisable}
        />

        <PrivateRoute path="/sample-error-recruiter" exact component={SampleError} />

        <Route path="/candidate/:userId" exact component={CandidateJobInfo} />

        <Route path="/candidate/:userId/sample-error-candidate" exact component={SampleError} />

        <PrivateRouteCandidate
          path="/candidate/tips/:userId"
          exact

          component={CandidateTips}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/test/:userId"
          exact

          component={VideoQuestionsTest}
        />
        {
          process.env.REACT_APP_IS_TEST_PAGE_ENABLED === "true" && <PrivateRouteCandidate path="/candidate/video-questions/create/:userId/:jobId/:questionId/test" exact component={TestPage} />
        }
        <PrivateRouteCandidate
          path="/candidate/video-questions/create/:userId/:jobId/:questionId"
          exact

          component={VideoQuestionsCreate}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/create/:userId/:jobId/:questionId/idv-check"
          exact

          component={IDVSession}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/create/:userId/:jobId/:questionId/share-code"
          exact

          component={ShareCode}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/create/:userId/:jobId/:questionId/:step/idv/completed/"
          exact
          component={IdvCompleted}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/create/:userId/:jobId/:questionId/:step"
          exact

          component={VideoQuestionsCreate}
        />
        <PrivateRouteCandidate
          path="/candidate/video-questions/preview/:userId"
          exact

          component={CandidateVideoPreview}
        />
        <PrivateRouteCandidate
          path="/candidate/saved/:userId"
          exact

          component={SavedForLater}
        />
        <PrivateRouteCandidate
          path="/candidate/calendar/:userId"
          exact

          component={CandidateCalendar}
        />
        <PrivateRouteCandidate
          path="/candidate/complete/:userId"
          exact

          component={CandidateComplete}
        />
        <PrivateRouteCandidate
          path="/candidate/interview-submitted/:userId"
          exact

          component={InterviewSubmitted}
        />
        <PrivateRouteCandidate
          path="/candidate/transferred/:userId"
          exact

          component={CanidateTransferred}
        />
        <Route
          path="/candidate/transferred-loading/:userId"
          exact

          component={CanidateTransferredLoading}
        />
        <Route
          path="/candidate/transferred-cancel/:userId"
          exact

          component={CanidateTransferredCancelled}
        />

        <Route path="/show/:id/not-found" exact component={PageNotFound} />

        {isTestPOEditorEnabled && (
          <Route path="/test-poeditor" exact component={POEditor} />
        )}

        <PrivateWrapper>
          <Switch>
            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="/not-access" component={CommonNotAccess} />
            {hasAdminRight && (
              <PrivateRoute path="/plans">
                <Plans />
              </PrivateRoute>
            )}
            {hasAdminRight && (
              <PrivateRoute path="/users" exact component={Users} />
            )}
            <PrivateRoute path="/stripe-verification-failed" exact>
              { isStripeVerificationFailed ? <StripeVerificationFailed /> : <Redirect to="/jobs" /> }
            </PrivateRoute>
            <PrivateRoute path="/account-verification" exact>
              { isAccountLocked ? <AccountVerification /> : <Redirect to="/jobs" /> }
            </PrivateRoute>
            <PrivateRoute path="/email-verification" exact>
              { isEmailVerified ? <Redirect to="/jobs" /> : <EmailVerificationPage /> }
            </PrivateRoute>
            <PrivateRoute
              path="/trial-completed"
              exact
            >
              { isTrialCompleted ? <TrialCompleted /> : <Redirect to="/jobs" /> }
            </PrivateRoute>
            <PrivateRoute
              path="/subscription-failed"
              exact
            >
              { isSubscriptionFailed ? <SubscriptionFailed /> : <Redirect to="/jobs" /> }
            </PrivateRoute>
            <PrivateRoute path="/jobs" exact component={Jobs} />
            <PrivateRoute path="/dashboard" exact component={Dashboard} />
            <PrivateRoute path="/jobs/create" exact component={CreateJob} />
            <PrivateRoute
              path="/jobs/overview/:jobId"
              exact

              component={JobOverview}
            />
            <PrivateRoute
              path="/jobs/preview/:jobId"
              exact

              component={JobInfo}
            />
            <PrivateRoute path="/jobs/edit/:id" exact component={CreateJob} />
            <PrivateRoute path="/jobs/invite/:id" exact component={JobInvite} />
            <PrivateRoute
              path="/jobs/candidate/:userId/:jobId"
              exact

              component={JobCandidate}
            />
            <PrivateRoute path="/companies" exact component={Companies} />
            <PrivateRoute path="/people" exact component={People} />
            <PrivateRoute
              path="/companies/overview/:id"
              exact

              component={CompaniesOverview}
            />
            <PrivateRoute
              path="/companies/create"
              exact

              component={CompanyCreate}
            />
            <PrivateRoute
              path="/companies/edit/:id"
              exact

              component={CompanyEdit}
            />
            <PrivateRoute
              path="/connect"
              exact

              component={ConnectToIntegration}
            />
            <PrivateRoute path="/integrations" exact component={Marketplace} />
            <PrivateRoute path="/security" exact component={Security} />
            {isTrialOrPaid && (
              <PrivateRoute path="/libraries" exact component={Libraries} />
            )}
            {hasAdminRight && (
              <PrivateRoute
                path="/scorecards"
                exact

                component={ScoreCardLibrary}
              />
            )}
            {isTrialOrPaid && (
              <PrivateRoute
                path="/libraries/create"
                exact

                component={CreateTemplate}
              />
            )}
            {isTrialOrPaid && (
              <PrivateRoute
                path="/libraries/edit/:id"
                exact

                component={EditTemplate}
              />
            )}

            <PrivateRoute path="/onboarding" exact strict>
              { !isSetupCompleted ? <Onboarding /> : <Redirect to="/jobs" /> }
            </PrivateRoute>

            <PrivateRoute
              path="/stripe-session/:id"
              exact
              component={StripeSession}
            />
            <PrivateRoute
              path="/connect/:type/:id"
              exact

              component={IdvCheckoutSession}
            />

            {hasAdminRight && (
              <PrivateRoute
                path="/scorecards/create"
                exact

                component={ScoreCardCreate}
              />
            )}

            {hasAdminRight && (
              <PrivateRoute
                path="/scorecards/edit/:id"
                exact

                component={ScoreCardCreate}
              />
            )}

            {/*  All invalid route patterns get redirected to this page
            regardless if recruiter or candidate */}
            <Redirect to="/page-not-found" />

          </Switch>
        </PrivateWrapper>
      </Switch>
    </React.Suspense>
  );
};

Router.defaultProps = {
  hasAdminRight: false,
  isTrialCompleted: false,
  isSubscriptionFailed: false,
  isAccountLocked: false,
  isAuthenticated: false
};

Router.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object
  }).isRequired,
  hasAdminRight: PropTypes.bool,
  isTrialCompleted: PropTypes.bool,
  isSubscriptionFailed: PropTypes.bool,
  isSetupCompleted: PropTypes.bool.isRequired,
  refreshStatus: PropTypes.func.isRequired,
  isTrialOrPaid: PropTypes.bool.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isAccountLocked: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

export default Router;
